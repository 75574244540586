.centered {
  text-align: center;
}

.main-content {
  margin-top: 10% !important;
}

.secondary-text {
  color: #8899A6 !important;
}

.footer-content {
  margin-top: 10% !important;
}